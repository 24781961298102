<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedOrganizationId"
                    :items="organizations"
                    item-text="organizationName"
                    item-value="organizationId"
                    label="機關"
                    :disabled="!permissionScope.organization || !isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                    @change="
                      getDepartments();
                      selectedDepartmentId = null;
                    "
                  >
                    <!-- <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          selectedOrganizationId = null;
                          selectedDepartmentId = null;
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template> -->
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedDepartmentId"
                    :items="departments"
                    item-text="departmentName"
                    item-value="id"
                    label="單位"
                    :disabled="!permissionScope.department || !isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedDepartmentId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="getUserList"
                      :disable="!isLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              用戶列表
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
              <span class="pt-5" v-if="canEdit">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[false]"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                :show-expand="false"
                @click:row="showExpanded"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <template v-slot:item.emailVerified="{ item }">
                  <span v-if="item.emailVerified === 1"> 已驗證 </span>
                  <span v-else> 尚未驗證 </span>
                </template>

                <template v-slot:item.createdTs="{ item }">
                  <span>{{
                    item.createdTs
                      ? moment
                          .unix(item.createdTs)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="props">
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="onEditItem(props.item, 'view')"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>查看詳細內容</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="cyan"
                        :style="{
                          cursor: 1 ? 'pointer' : 'not-allowed',
                        }"
                        v-on="on"
                        @click="1 ? onEditItem(props.item) : ''"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>編輯</span>
                  </v-tooltip> -->
                  <v-btn
                    v-if="canEdit"
                    class="mx-1"
                    dark
                    small
                    color="blue darken-5"
                    outlined
                    :style="{
                      cursor: 1 ? 'pointer' : 'not-allowed',
                    }"
                    @click="1 ? onEditItem(props.item) : ''"
                    >更新使用者</v-btn
                  >
                  <v-btn
                    v-if="canEdit"
                    class="mx-1"
                    dark
                    small
                    color="blue darken-3"
                    @click="onResetItem(props.item)"
                    >重設密碼</v-btn
                  >
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="tableData.length"
                :loading="!isLoaded"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="openEditDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span class="text-h5">
              {{ $t(`common["User"]`) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" v-if="!isReset">
                    <v-text-field
                      v-model="editItem.userName"
                      label="用戶顯示名稱"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!isReset">
                    <v-text-field
                      v-model="editItem.email"
                      label="帳號Email"
                      :disabled="viewOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!isReset">
                    <v-select
                      v-model="editItem.organizationId"
                      :items="organizations"
                      item-text="organizationName"
                      item-value="organizationId"
                      label="機關"
                      :disabled="!permissionScope.organization || viewOnly"
                      @change="
                        getDepartments(editItem.organizationId);
                        editItem.departmentId = null;
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!isReset">
                    <v-select
                      v-model="editItem.departmentId"
                      :items="departments"
                      item-text="departmentName"
                      item-value="id"
                      :label="$t(`common['Select Department']`)"
                      :disabled="!permissionScope.department || viewOnly"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!isReset">
                    <v-select
                      v-model="editItem.roleId"
                      :items="roles"
                      item-text="roleDescription"
                      item-value="roleId"
                      :label="$t(`common['Account Role']`)"
                      :disabled="viewOnly"
                      @change="getRoles"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="isNew || isReset">
                    <v-radio-group
                      v-model="editItem.loginProfile.autogenerate"
                      mandatory
                      class="large-radio"
                    >
                      <v-radio label="自動產生密碼" :value="true"></v-radio>
                      <v-radio label="手動建立密碼" :value="false"></v-radio>
                    </v-radio-group>

                    <v-text-field
                      v-if="
                        (isNew || isReset) &&
                        !editItem.loginProfile.autogenerate
                      "
                      v-model="editItem.loginProfile.password"
                      label="密碼"
                      type="password"
                      :disabled="viewOnly"
                      :rules="
                        (isNew || isReset) &&
                        !editItem.loginProfile.autogenerate
                          ? rules.required
                          : []
                      "
                    >
                    </v-text-field>

                    <v-switch
                      v-if="
                        (isNew || isReset) &&
                        !editItem.loginProfile.autogenerate
                      "
                      v-model="editItem.loginProfile.resetRequired"
                      :ripple="false"
                      class="d-inline-flex mt-0 pt-0 switch ms-auto"
                      hide-details
                    ></v-switch>
                    <span
                      class="px-3"
                      v-if="
                        (isNew || isReset) &&
                        !editItem.loginProfile.autogenerate
                      "
                    >
                      要求使用者在登入時變更密碼</span
                    >

                    <v-checkbox
                      class="large-checkbox pt-2"
                      v-if="isReset"
                      v-model="editItem.loginProfile.sendEmail"
                      label="發信通知用戶"
                    >
                    </v-checkbox>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" v-if="(isNew || isReset) && !editItem.loginProfile.autogenerate">
                    <v-text-field
                      v-model="editItem.loginProfile.password"
                      label="密碼"
                      type="password"
                      :disabled="viewOnly"
                      :rules="rules.name"
                    >
                    </v-text-field>

                    <v-switch
                      v-model="editItem.loginProfile.resetRequired"
                      :ripple="false"
                      class="d-inline-flex mt-0 pt-0 switch ms-auto"
                      hide-details
                    ></v-switch>
                    <span class="px-3"> 要求使用者在登入時變更密碼</span>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn
              v-if="!viewOnly"
              color="blue darken-1"
              text
              @click="onSaveEditItem"
            >
              {{ isNew ? "寄出帳號開通信" : $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { checkPermission } from "src/util/utils";

import {
  fetchUsers,
  fetchOrganizations,
  fetchDepartments,
  fetchRoles,
} from "src/apis/fetchData";
import { updateUser } from "src/apis/updateData";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  name: "Accounts",
  components: { Pagination },
  data() {
    return {
      moment: moment,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      reviewOptions: [
        {
          value: 0,
          title: "審核中",
        },
        {
          value: 1,
          title: "核准",
        },
        {
          value: 2,
          title: "待補件",
        },
        {
          value: 3,
          title: "退回",
        },
      ],
      selectedReviewStatus: null,
      isLoaded: true,
      isNew: false,
      isReset: false,
      viewOnly: false,
      openApproveDialog: false,
      openEditDialog: false,
      isDepartmentLoaded: true,
      isOrganizationLoaded: true,
      isRoleLoaded: true,
      selectedDepartmentId: null,
      selectedOrganizationId: null,
      selectedRoleId: null,
      departments: [],
      organizations: [],
      roles: [],
      singleExpand: true,
      expanded: [],
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: true,
          value: "id",
        },
        {
          text: "用戶名稱",
          sortable: true,
          value: "userName",
        },
        {
          text: "單位",
          sortable: true,
          value: "departmentName",
        },
        {
          text: "Email Verified",
          sortable: true,
          value: "emailVerified",
        },
        {
          text: "Email",
          sortable: true,
          value: "email",
        },
        {
          text: "角色權限",
          sortable: true,
          value: "roleDescription",
        },
        {
          text: "建立時間",
          sortable: true,
          filterable: false,
          value: "createdTs",
        },
        // {
        //   text: "更新時間",
        //   sortable: true,
        //   filterable: false,
        //   value: "updatedTs",
        // },
        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      reviewStatus: null,
      comment: null,
      approveItem: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      defaultEditItem: {
        userName: null,
        organizationId: null,
        departmentId: null,
        loginProfile: {},
      },
      editItem: {
        userName: null,
        organizationId: null,
        departmentId: null,
        loginProfile: {},
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    isActionDisabled() {
      return !(this.isPeriodsLoaded && this.isBudgetsLoaded);
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:AccountManage",
          "EditAdminUsers",
          true
        );
      }
      return false;
    },
  },
  watch: {},
  created() {
    // this.selectedDepartmentId = this.$route.query.departmentId
    //   ? parseInt(this.$route.query.departmentId)
    //   : null;
    // this.selectedOrganizationId = this.$route.query.organizationId
    //   ? parseInt(this.$route.query.organizationId)
    //   : null;
    this.selectedRoleId = this.roles[0] ? parseInt(this.roles[0].roleId) : null;
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
  },
  methods: {
    showExpanded(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    init() {
      this.getOrganizations();
      this.selectedOrganizationId = this.organizationId;
      if (!this.permissionScope.department) {
        this.selectedDepartmentId = this.departmentId;
      }
      this.getDepartments();
      this.getUserList();
      this.getRoles();
    },
    getUserList() {
      this.isLoaded = false;
      this.pagination.page = 1;
      let query = {
        organizationId: this.selectedOrganizationId,
        departmentId: this.selectedDepartmentId,
      };
      fetchUsers(this.merchantId, query)
        .then((res) => {
          console.log("fetchUsers done", res);
          this.tableData = [...res.data];
        })
        .catch((e) => {
          console.log("fetchUsers failed", e);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getOrganizations() {
      this.isOrganizationLoaded = false;
      // let query = {
      //   organizationId: this.organizationId,
      // };
      fetchOrganizations(this.merchantId, null)
        .then((res) => {
          console.log("fetchOrganizations done", res);
          this.organizations = [...res.data];
        })
        .catch((e) => {
          console.log("fetchOrganizations failed", e);
        })
        .finally(() => {
          this.isOrganizationLoaded = true;
        });
    },
    getDepartments(inputDepartmentId) {
      this.isDepartmentLoaded = false;
      let query = {
        organizationId:
          inputDepartmentId ||
          this.selectedOrganizationId ||
          this.organizationId,
      };
      fetchDepartments(this.merchantId, query)
        .then((res) => {
          console.log("fetchDepartments done", res);
          this.departments = [...res.data];
        })
        .catch((e) => {
          console.log("fetchDepartments failed", e);
        })
        .finally(() => {
          this.isDepartmentLoaded = true;
        });
    },
    getRoles() {
      this.isRoleLoaded = false;
      let query = {
        // organizationId: this.organizationId,
      };
      fetchRoles(this.merchantId, query)
        .then((res) => {
          console.log("fetchRoles done", res);
          this.roles = [...res.data];
        })
        .catch((e) => {
          console.log("fetchRoles failed", e);
        })
        .finally(() => {
          this.isRoleLoaded = true;
        });
    },
    onEditItem(item, type) {
      if (item) {
        this.editItem = Object.assign({}, item);
        this.isNew = false;
        this.isReset = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        // 預設和管理者同機關/單位
        this.editItem.organizationId = this.organizationId;
        this.editItem.departmentId = this.departmentId;
        this.isNew = true;
        this.isReset = false;
      }
      if (type === "view") {
        this.viewOnly = true;
      } else {
        this.viewOnly = false;
      }
      this.openEditDialog = true;
    },
    onResetItem(item, type) {
      if (item) {
        this.editItem = Object.assign(
          {
            userName: null,
            loginProfile: {},
          },
          item
        );
        this.isNew = false;
        this.isReset = true;
      } else {
        this.editItem = Object.assign(
          {
            userName: null,
            loginProfile: {},
          },
          this.defaultEditItem
        );
        this.isNew = true;
        this.isReset = true;
      }
      if (type === "view") {
        this.viewOnly = true;
      } else {
        this.viewOnly = false;
      }
      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      if (valid) {
        console.log("save edit item");
        // update user data

        if (
          this.editItem.loginProfile &&
          this.editItem.loginProfile.autogenerate
        ) {
          this.editItem.loginProfile.resetRequired = true;
        }
        console.log(JSON.stringify(this.editItem));

        updateUser(this.merchantId, this.editItem)
          .then((res) => {
            console.log("updateUserDone done", res);
            let self = this;
            setTimeout(function () {
              self.getUserList();
              self.showSuccess("設定成功");
              self.openEditDialog = false;
            }, 10);
          })
          .catch((e) => {
            console.log("updateUserDone failed", e);
            let errmsg = this.isNew ? "新增用戶失敗" : "更新用戶失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    closeEditDialog() {
      this.openEditDialog = false;
      // 避免編輯dialog的下拉選單選動造成外部選單的異常
      this.getDepartments();
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    showSuccess(message) {
      let self = this;
      this.$swal({
        title: self.$i18n.t(`common["Success"]`),
        html: message,
        type: "success",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
