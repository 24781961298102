<template>
  <v-row class="text-center px-4 align-center" wrap v-if="!loading">
    <v-col
      cols="12"
      md="4"
      class="d-flex align-center"
      :class="{
        'justify-center': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
      }"
      v-if="showItemsPerPage"
    >
      <!-- <span class="text-body me-3 text-sm">
                    {{ $t(`common['Rows per page']`) }}
                  </span> -->
      <span class="text-body me-3 text-sm"> 共 {{ total }} 筆資料 </span>
      <div style="width: 200px">
        <v-select
          v-model="pagination.itemsPerPage"
          :items="itemsPerPage"
          item-text="name"
          item-value="value"
          :label="$t(`common['Rows per page']`)"
          dense
          outlined
          hide-details
          :disabled="loading"
          @change="pagination.itemsPerPage = parseInt($event, 10)"
        >
        </v-select>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="8"
      class="ml-auto d-flex"
      :class="{
        'justify-center': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
        'justify-end': !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm),
      }"
    >
      <v-pagination
        prev-icon="fa fa-angle-left"
        next-icon="fa fa-angle-right"
        class="pagination"
        color="#D81B60"
        v-model="pagination.page"
        :length="pagination.pageCount"
        :total-visible="$vuetify.breakpoint.mobile ? 5 : 10"
        circle
      ></v-pagination>
    </v-col>
    <!-- <v-col class="text-truncate d-flex justify-end" cols="6" md="3">
      共 {{ total }} 筆資料
    </v-col> -->

    <!-- <v-col cols="6" md="3">
      <v-text-field
        v-model="page"
        :label="$t(`common['Go to page']`)"
        type="number"
        outlined
        hide-details
        dense
        :disabled="loading"
        @change="pagination.page = parseInt($event, 10)"
      ></v-text-field>
    </v-col> -->
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import variables from "src/util/variables";

export default {
  name: "Pagination",
  components: {},
  props: {
    pagination: {
      type: Object,
      default: function () {
        return { page: 1, pageCount: 0, itemsPerPage: 10 };
      },
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showItemsPerPage: {
      type: Boolean,
      default: true,
    },
    dataTable: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      page: null,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    itemsPerPage() {
      return variables.itemsPerPage;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.$emit("update");
        this.$vuetify.goTo(0);
        // this.$vuetify.goTo(".v-data-table-header", {
        //   offset: 50,
        //   container: ".v-data-table__wrapper",
        // });
        // this.$vuetify.goTo(this.dataTable);
        // if (this.dataTable && this.$refs[this.dataTable]) {
        //   this.$vuetify.goTo(this.$refs[this.dataTable]);
        // } else {
        //   this.$vuetify.goTo(0);
        // }
        // this.$vuetify.goTo(0);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
